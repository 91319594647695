a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

.change-theme-box {
  position: fixed;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 20px;
  background-color: #a8518e;
  z-index: 9999;
  border-radius: 10px 10px 0 0;
}

.highlight {
  background-color: #ffb7e9 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 1;
  filter: invert(0.5);
}

.valuetable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.valuetable td,
.valuetable th {
  border: 2px solid purple;
  padding: 8px;
}

